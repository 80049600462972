<template>
  <RjppForm mode="Ubah" module="RJPP"> </RjppForm>
</template>

<script>
import RjppForm from './form';

const RjppUpdate = {
  name: 'RjppUpdate',
  components: { RjppForm },
};

export default RjppUpdate;
</script>
